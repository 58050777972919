import { insurance_payment } from '@axo/shared/data-access/types';
import { createMandate, findMandates, getMandate } from './mandate.utils';

/**
 * card mandate
 *
 * https://gitlab.com/a2755/services/insurance_payment_mandate/-/blob/main/api/openapi.json
 */

const mandateType: insurance_payment.PaymentMandateType = 'card';

export const createCardMandate =
  createMandate<insurance_payment.CreateCardPaymentMandateRequest>(mandateType);

export const findCardMandates = findMandates(mandateType);

export const getCardMandate = getMandate(mandateType);
