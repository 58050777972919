import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ITranslationContext, TranslationContext } from './context';
import { i18n } from './instance';

export type ITranslationProvider = {
  children: ReactNode;
} & ITranslationContext;

export const TranslationProvider = ({
  children,
  language,
  ns,
}: ITranslationProvider) => {
  const [displayLanguage, setDisplayLanguage] = useState('');

  useEffect(() => {
    if (language === displayLanguage) return;

    i18n.changeLanguage(language);
    setDisplayLanguage(language);
  }, [language, displayLanguage]);

  return (
    <TranslationContext.Provider
      value={useMemo(() => ({ language, ns }), [language, ns])}
    >
      {children}
    </TranslationContext.Provider>
  );
};
